import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentContainer from "../components/contentContainer"
import EventDetail from "../components/eventDetail"
import { getEventData, getIcsLink } from "../helpers/apiService"
import { validateUrl } from "../helpers/formatter"
import TwoColumnComponent from "../components/twoColumnComponent"

class TimelinePost extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            showForm: false
        }
    }

    componentDidMount() {
        const isEvent = this.props.data.entry.edges[0].node.frontmatter.isevent
        const eventKey = this.props.data.entry.edges[0].node.frontmatter.eventkey
        if(isEvent && eventKey) { 
            getEventData(eventKey)
                .then(response => {
                    if(response.ok) {
                        return response.json()
                    } else {
                        throw new Error()
                    }
                })
                .then(data => {
                    //check if user can sign up for event
                    let showForm = data[0]?.active && !data[0]?.full
                    if(data[0]?.eventTime) {
                        if (new Date(data[0].eventTime) < new Date()) {
                            showForm = false
                        }
                    }
                    this.setState({data: data, showForm: showForm})
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    render() {
        const post = this.props.data.entry.edges[0].node
        const { showForm, data } = this.state
        const siteTitle = "post"
        const hasUrl = (post.frontmatter.url && post.frontmatter.url !== "")
        let content
        if(post.frontmatter.isevent) {
                const speakers = post.frontmatter.speakers
                let icsLink = null
                if(data[0]) {
                    icsLink = getIcsLink(data[0].id)
                }
                let image 
                if(post.frontmatter.eventimage && post.frontmatter.eventimage !== "") {
                    image = post.frontmatter.eventimage
                }
                content = 
                        <ContentContainer paddingMobile><div className="columns is-multiline">
                            <div className="column is-12">
                            <EventDetail title={post.frontmatter.title} eventtime={post.frontmatter.eventtime} eventdate={post.frontmatter.date}
                                    eventplace={post.frontmatter.eventplace} isUrl={false}
                                    speakers={speakers} linkDestination="/timeline" icsLink={icsLink}
                                    eventImage={image} showForm={showForm} eventId={data[0]?.id}>
                                <div>
                                    <div className="mt-3" dangerouslySetInnerHTML={{ __html: post.html }}></div>
                                    {hasUrl && 
                                        <div className="mt-3">
                                            <p> Mehr Informationen findest du <a href={validateUrl(post.frontmatter.url)}>hier</a></p>
                                        </div>
                                    }
                                </div>
                            </EventDetail>
                            </div>
                           </div>
                        </ContentContainer>
            
        } else {
            content = 
                <ContentContainer paddingMobile paddingMobileTop paddingTop>
                    <TwoColumnComponent title={post.frontmatter.seoTitle || post.frontmatter.title} titleContent={post.frontmatter.subtitle} backButton linkDestination="/timeline">
                        <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
                    </TwoColumnComponent>
                </ContentContainer>
        }
        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO title={post.frontmatter.seoTitle || post.frontmatter.title} description={post.frontmatter.seoDescription} />
                <div className="content">
                        {content}
                </div>
            </Layout>
        )
    }
}

export default TimelinePost

export const query = graphql`
    query($slug: String!, $content: String!) {
        overview: markdownRemark(fields: {
            slug: {
                eq: $slug
            }
        }) {
            frontmatter {
                date
                title
            }
            html
        }
        entry: allMarkdownRemark(filter: {fileAbsolutePath: {regex: $content}}) {
            edges {
              node {
                fields {
                  slug
                }
                fileAbsolutePath
                frontmatter {
                    date
                    title
                    subtitle
                    isevent
                    eventkey
                    eventtime
                    eventplace
                    url
                    speakers {
                        company
                        description
                        name
                        imgUrl {
                            childImageSharp {
                                fixed(width: 200, height: 200) {
                                ...GatsbyImageSharpFixed
                                }
                            }
                        }
                    }
                    eventimage {
                        childImageSharp {
                            fluid {
                              ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    seoTitle
                    seoDescription
                }
                html
              }
            }
          }
    }
`